import { InjectionToken } from '@angular/core';
import { ConditionOperator } from '@app/core/enums/condition-operator.enum';
import { EntityTypeEnum } from '@app/core/enums/entity-type.enum';
import { FieldTypeEnum } from '@app/core/enums/field-type-enum';
import { ValidatorType } from '@app/core/enums/validator-type.enum';
import { AbstractBean } from '@app/core/model/abstract-bean';
import { Entity } from '@app/core/model/entities/entity';
import { FieldValidator } from '@app/core/model/other/field-validator';
import { StylesConditions } from '@app/shared/extra/utils';
import { Expose, Type } from 'class-transformer';

export const FIELD_GROUP_CONFIG_INJECTION = new InjectionToken<FieldGroup>('field_group_config_injection');
export const FIELD_CONFIG_INJECTION = new InjectionToken<FieldConfig>('field_config_injection');
export const FIELD_ENTITY_INJECTION = new InjectionToken<Entity>('field_entity_injection');
export const FIELD_PRECONDITIONS_INJECTION = new InjectionToken<boolean>('field_preconditions_injection');
export const FIELD_ASYNC_PRECONDITIONS_INJECTION = new InjectionToken<boolean>('field_async_preconditions_injection');
export const FIELD_PERMISSIONS_INJECTION = new InjectionToken<string[]>('field_permissions_injection');
export const FIELD_EXTRA_DATA = new InjectionToken<any>('field_data_injection');
export const FIELD_EVENTS_ORIGIN = new InjectionToken<any>('field_events_origin');

export interface FormNode {
  label?: string;
  hierarchy?: string[];
  graphqlTypename: string;
}

export interface Form extends FormNode {
  code: string;
  label?: string;
  entityType: EntityTypeEnum;
  sections: Section[];
}

export class Section extends AbstractBean implements FormNode {
  public code: string;
  public order: number;
  public formCode: string;
  public organizationId: string;

  public label?: string;
  public emptyLabel?: string;

  public customOptions?: Record<string, any>;

  @Expose({name: '__typename'})
  public graphqlTypename: string;
  public hierarchy: string[];

  @Expose({name: 'fieldGroupsList'})
  @Type(() => FieldGroup)
  public fieldGroups: FieldGroup[];
  @Expose({name: 'conditionsToViewList'})
  public conditionsToView: Condition[];
  @Expose({name: 'conditionsToEditList'})
  public conditionsToEdit: Condition[];
}

/**
 * SectionInputs for creating and updating Sections.
 */
export type UpdateSectionInput = {
  label: string;
};

export type CreateSectionInput = {
  code: string;
  label: string;
  order: number;
  entityType: EntityTypeEnum;
  formCode: string;
};

export class FieldGroup extends AbstractBean implements FormNode {
  public code: string;
  public order: number;
  public fieldGroupType: string;

  public label: string;
  public emptyLabel?: string;
  public tooltip?: string;

  public customOptions?: Record<string, any>;

  @Expose({name: '__typename'})
  public graphqlTypename: string;
  public hierarchy: string[];

  @Expose({name: 'fieldConfigsList'})
  @Type(() => FieldConfig)
  public fieldConfigs: FieldConfig[];
  @Expose({name: 'conditionsToViewList'})
  public conditionsToView: Condition[];
  @Expose({name: 'conditionsToEditList'})
  public conditionsToEdit: Condition[];

  /**
   * Path to the properties referenced by this field group within the current entity.
   */
  public get propertiesPath(): string {
    return this.customOptions?.['propertiesPath'];
  }

  /**
   * Classification code that must be used to load classification categories for a field group of type 'classification'.
   */
  public get classificationCode(): string {
    return this.customOptions?.['classificationCode'];
  }
}

export class FieldConfig extends AbstractBean implements FormNode {
  public fieldCode: string;
  public order: number;

  public customOptions?: Record<string, any>;

  @Expose({name: '__typename'})
  public graphqlTypename: string;
  public hierarchy: string[];

  @Type(() => Field)
  public field: Field;
  @Expose({name: 'conditionsToViewList'})
  public conditionsToView: Condition[];
  @Expose({name: 'conditionsToEditList'})
  public conditionsToEdit: Condition[];


  /**
   * Retrieves the label for a pictogram based on the provided key.
   * @param {string} key The key used to identify the specific pictogram.
   * @returns {string} The pictogram associated with the given key. Returns undefined if the key is not found.
   */
  public pictogram(key: string): string {
    return this.customOptions?.['pictograms'][key];
  }

  /**
   * Field code associated with this FieldConfig.
   */
  public get code(): string {
    return this.fieldCode;
  }

  /**
   * List of pictograms to use if exists.
   * @returns {object} Returns an object that contains pairs of key/value.
   * The value is the name of the pictogram to render.
   */
  public get pictograms(): object {
    return this.customOptions?.['pictograms'];
  }

  /**
   * Boolean that returns true if the field contains pictograms.
   * @returns {boolean} Returns true if the field contains pictograms, false otherwise.
   */
  public get hasPictograms(): boolean {
    return !!this.customOptions?.['pictograms'];
  }

  /**
   * Boolean that returns true if the field should be displayed with a pictogram and a text.
   * @returns {boolean} Returns true if the field's pictogram should be displayed with a text, false otherwise.
   */
  public get isPictogramWithText(): boolean {
    return this.customOptions?.['withText'] ?? false;
  }

  /**
   * Field's entityType.
   */
  public get fieldEntityType(): EntityTypeEnum {
    return this.field.entityType;
  }

  /**
   * Field's label.
   */
  public get label(): string {
    return this.field.label;
  }

  /**
   * Prefixed translation key of the field group's tooltip text, or undefined if the field group has no tooltip.
   */
  public get tooltip(): string {
    return this.field.tooltip;
  }

  /**
   * Prefixed translation key of the field group's suffix (i.e. unit), or undefined if the field group has no suffix.
   */
  public get suffixType(): string {
    return this.customOptions?.['suffixType'] && 'SUFFIX.' + this.customOptions?.['suffixType'].toUpperCase();
  }

  /**
   * Return the field's path as an Array of string
   */
  public get fieldPath(): string[] {
    return this.field.parentPath.concat(this.fieldCode);
  }

  /**
   * Whether the field is read-only (not editable)
   */
  public get computed(): boolean {
    return !!this.field.computed;
  }

  /**
   * CSS properties that should be applied to Field/Section/Cell in conditional form
   * @return Object containing css properties as keys and a list of condition/value as value
   */
  public get customCss(): StylesConditions {
    return this.customOptions?.['customCss'] ?? {};
  }

  /**
   * Returns an array of cell classes associated with the current field configuration.
   *
   * @return An array of cell classes.
   */
  public get cellClasses(): string[] {
    return this.customOptions?.['cellClasses'] ?? [];
  }

  /**
   * Returns the classification level of the field.
   * @returns {number} A numeric representation of the classification level of the field.
   */
  public get classificationLevel(): number {
    return this.customOptions?.['classificationLevel'];
  }
}

export class Field extends AbstractBean {
  public code: string;
  public fieldType: FieldTypeEnum;
  public checkType: string;
  public organizationId: string;
  public computed: boolean;
  @Expose({name: 'fieldValuesList'})
  public fieldValues?: any[];
  @Expose({name: 'validatorsList'})
  @Type(() => FieldValidator)
  public validators?: FieldValidator[];
  @Expose({name: 'parentPathList'})
  public parentPath: string[];
  public formula?: JSON;
  public classificationCode: string;

  public label: string;
  public tooltip?: string;

  /**
   * Return the field's path as an Array of string
   */
  public get fieldPath(): string[] {
    return this.parentPath.concat(this.code);
  }
}

/**
 * FieldInputs for creating and updating Fields.
 */
export type UpdateFieldInput = {
  label: string;
  tooltip: string;
};

export interface CreateFieldInput {
  code: string;
  label: string;
  tooltip: string;
  entityType: EntityTypeEnum;
  fieldType: FieldTypeEnum;
  validators?: FieldValidator[];
  fieldValues?: string[];
}

export interface FieldConfigInput {
  tooltip?: string;
  emptyLabel?: string;
  suffixType?: SuffixType;
  formatType?: FormatType;
  customOptions?: Record<string, any>;
}

export interface Condition {
  field: string;
  operator: ConditionOperator;
  value?: any;
  applyValue?: string; // Value to apply if condition is true
}

export interface FieldLocationInformation {
  code: string;
  currency: string;
  classificationType: string;
  isTb: boolean;
  label?: string;
  entityType?: EntityTypeEnum;
}

export declare type FormatType =
  'integer'
  | 'percent'
  | 'precise_number'
  | 'numeric'
  | 'duration'
  | 'scientific'
  | 'engineering';

export declare type SuffixType =
  'currency'
  | 'currency_year'
  | 'currency_excluding_taxes'
  | 'currency_squarefoot'
  | 'currency_squaremeter'
  | 'currency_squaremeter_year'
  | 'floor'
  | 'kgeq'
  | 'kgeqco2_kwh_year'
  | 'kgeqco2_squaremeter'
  | 'kgeqco2_squaremeter_year'
  | 'kmh'
  | 'kwh'
  | 'kwhpe'
  | 'kwhpe_squaremeter'
  | 'kwhpe_year'
  | 'kwh_squaremeter'
  | 'kwh_squaremeter_year'
  | 'kwh_year'
  | 'year'
  | 'month'
  | 'hour'
  | 'percent'
  | 'squarefoot'
  | 'squaremeter'
  | 'unit'
  | 'mm'
  | 'm'
  | 'cm'
  | 'repkman';

/**
 * Check if a validatorType is field dependant or not
 * @param type : validator type
 *
 * @return true if validatorType is fieldDependant otherwise false
 */
export function isValidatorFieldDependant(type: ValidatorType): boolean {
  return [
    ValidatorType.AFTER_OTHER_DATE,
    ValidatorType.BEFORE_OTHER_DATE
  ]
    .includes(type);
}
