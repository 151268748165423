export const environment = {
  name: 'staging',
  production: true,
  auth0: {
    domain: 'auth.tbmaestro.com',
    clientId: 'ynmxMRT4ICQXO8xqBLUYJTaje1PGn33U',
    audience: 'https://backendstaging.tbmaestro.net'
  },
  backend: {
    baseUrl: 'https://backendstaging.tbmaestro.net',
    graphql: {
      endpoint: '/graphql',
    },
    download: {
      endpoint: '/presignedurl',
    },
    jmap: {
      endpoint: '/jmapauth'
    },
    retool: {
      endpoint: '/retool/embed-url/'
    },
    websocket: {
      responseChannel: '/user/graphql.response',
      requestChannel: '/graphql.request'
    }
  },
  profile: {
    accountsPortal: 'https://accounts.tbmaestro.com'
  },
  scripts: {
    jmapUrl: 'https://backendstaging.tbmaestro.net/jmap',
    metarealSDK: 'https://stage.metareal.com/playersdk/playersdk.min.1.0.js'
  },
  apiKey: {
    googleMaps: 'AIzaSyCYPvVQxK48seq3RMQGKC760B3g9a4vXvM'
  },
  logRocket: {
    enabled: true,
    appId: 'avsvs0/3t-staging'
  }
};
